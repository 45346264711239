import React from 'react';
import { useTranslation } from 'next-i18next';
import { LangType } from '../Header/types';
import styles from './index.module.scss';
import Popup from '../../../base/Popup';

export type IProps = {
  type: 'menu' | 'lang';
  isOpen: boolean;
  toggleDrawer: () => void;
  langList: Array<LangType>;
  lang: LangType;
  handleChangeLanguage: (type: LangType) => void;
  onLogout: () => void;
};

const TopBarFnPopup = (props: IProps) => {
  const { isOpen, toggleDrawer, type, langList, lang, handleChangeLanguage, onLogout } = props;
  const { t } = useTranslation('mobile');

  return (
    <Popup
      visible={isOpen}
      onClose={() => {
        toggleDrawer();
      }}
    >
      {type === 'menu' ? (
        <div className={styles.container}>
          <div className={styles.top}>
            <p className={styles.title}>{t('进线用户链路-更多功能')}</p>
            <div className={styles.close} onClick={toggleDrawer} />
          </div>
          <div className={styles.list}>
            <div
              className={styles.item}
              onClick={(e) => {
                e.stopPropagation();
                onLogout();
              }}
            >
              <div className={styles.itemLeft}>
                <div className={styles.logoutIcon} />
                <p>{t('进线用户链路-退出登录')}</p>
              </div>
              <div className={styles.arrow} />
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.top}>
            <p className={styles.title}>{t('进线用户链路-语言切换')}</p>
            <div className={styles.close} onClick={toggleDrawer} />
          </div>
          <div className={styles.list}>
            {langList.map((item) => {
              return (
                <div
                  className={`${styles.item} ${item === lang ? styles.act : ''}`}
                  key={item}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleChangeLanguage(item);
                  }}
                >
                  <p>{item === 'en' ? 'English' : '中文'}</p>
                  <div className={styles.arrow} />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </Popup>
  );
};

export default TopBarFnPopup;
